<template>
  <div>
    <modal :options="{close: true}" @close="$emit('close')">
      <div class="title">Calendario</div>
      <div class="body">
        <calendario :options="calendario_config" :eventos="eventos" />
      </div>
      <div class="footer"></div>
    </modal>
  </div>
</template>

<script>
  import modal from '@/components/Modal';
  import calendario from '@/components/Calendar';

  import apiUsuario from '@/apps/formalizacion/api/v2/usuarios'

  export default {
    components: {
      modal,calendario
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: 0
          }
        }
      }
    }
    ,data() {
      return {
        calendario_config: {
          agregar_eventos: false
          // ,agregar_eventos_js: false
          ,agregar_invitados: false
          ,editar_date: false
          ,editar_location: false
        }
        ,eventos: []
      }
    }
    ,mounted() {
      this.obtener_eventos();
    }
    ,methods: {
      async obtener_eventos() {
        try {
          this.eventos = (await apiUsuario.cuenta_obtener_eventos()).data;

          this.$log.info('eventos', this.eventos);
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>