<template>
  <div>
    <h1 class="col-sm-12 text-right mt-4 mb-4">Solicitud {{ solicitud.solicitud }}</h1>
    <button class="btn btn-info mr-2" @click="modal_informacion_adicional=true">Información de contacto</button>

    <div class="row mb-2">
      <div class="col-sm-12 text-right">
        <button class="btn btn-outline-secondary mr-2" @click="modal_calendario=true">Calendario</button>
        <button class="btn btn-secondary mr-2" @click="modal_observaciones=true">Observaciones</button>
        <button class="btn btn-info mr-2" @click="modal_documentos=true">Documentación</button>
        <button v-if="incidencias_abiertas > 0" class="btn btn-warning mr-2" @click="modal_incidencias=true">Incidencias</button>
        <button class="btn btn-danger" @click="modal_no_viable=true">Marcar como inválida</button>
      </div>
    </div>

    <Step css_class="mb-4" :pasos="pasos" :activo="etapa" title="ETAPAS" :max_width="true" />

    <div v-if="solicitud_cargada" class="mb-4">
      <div v-if="etapa == 'inicio'" class="row">
        <Subasignacion @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" />
      </div>

      <div v-if="etapa == 'subasignacion'">
        <DocumentacionBasica @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" :solicitud="solicitud" />
      </div>

      <div v-if="etapa == 'documentacion_basica'">
        <DictamenCliente :solicitud="solicitud" @update="obtener_solicitud" @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" />
      </div>

      <div v-if="etapa == 'dictamen_cliente'">
        <DictamenInmueble :solicitud="solicitud" @update="obtener_solicitud" @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true"/>
      </div>

      <div v-if="etapa == 'dictamen_inmueble'">
        <SolicitudCLG @avanzar="avanzar_etapa" :solicitud="solicitud" @retroceder="retroceder_etapa=true" />
      </div>

      <div v-if="etapa == 'solicitud_clg'">
        <CargaCLG @avanzar="avanzar_etapa" @update="obtener_solicitud" @retroceder="retroceder_etapa=true" :solicitud="solicitud" />
      </div>

      <div v-if="etapa == 'cargar_clg'">
        <DocumentacionCompleta @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" @actualizar="cerrar_retroceder_actualizar" :solicitud="solicitud" />
      </div>

      <div v-if="etapa == 'documentacion_completa'">
        <Cita @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" :solicitud="solicitud" />
      </div>

      <div v-if="etapa == 'firma_cita'">
        <Firma @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" :solicitud="solicitud" />
      </div>

      <div v-if="etapa == 'contrato_firmado'">
        <Finalizacion @avanzar="avanzar_etapa" @retroceder="retroceder_etapa=true" />
      </div>

      <Retroceder v-if="retroceder_etapa" :tipo_usuario="2" :solicitud="solicitud" @cancelar="retroceder_etapa=false" @actualizar="cerrar_retroceder_actualizar" />
      <Incidencia v-if="modal_incidencias" :solicitud="solicitud" @close="modal_incidencias=false" @update="obtener_solicitud" />
      <Observacion v-if="modal_observaciones" :solicitud="solicitud" @update="obtener_solicitud" :observacion_tipo_usuario="true" @close="modal_observaciones=false" />
      <Documento v-if="modal_documentos" :solicitud="solicitud" @update="obtener_solicitud" @close="modal_documentos=false" />
      <NoViable v-if="modal_no_viable" @cancelar="modal_no_viable=false" :solicitud="solicitud"/>
      <Calendario v-if="modal_calendario" :solicitud="solicitud" @close="modal_calendario=false" />
      <InformacionAdicionalSolicitud v-if="modal_informacion_adicional" :solicitud="solicitud" @close="modal_informacion_adicional=false" />

    </div>
  </div>
</template>

<script type="text/javascript">
  import Step from '@/components/Step'
  import Calendar from '@/components/Calendar'
  import InformacionAdicionalSolicitud from '@/apps/formalizacion/Componentes/InformacionAdicionalSolicitud'


  // Etapas en componentes
  import Subasignacion from './Notario/Subasignacion'
  import DocumentacionBasica from './Notario/DocumentacionBasica'
  import DictamenCliente from './Notario/DictamenCliente'
  import DictamenInmueble from './Notario/DictamenInmueble'
  import SolicitudCLG from './Notario/SolicitudCLG'
  import CargaCLG from './Notario/CargaCLG'
  import DocumentacionCompleta from './Notario/DocumentacionCompleta'
  import Cita from './Notario/Cita'
  import Firma from './Notario/Firma'
  import Finalizacion from './Notario/Finalizacion'
  import Retroceder from '@/apps/formalizacion/Componentes/Retroceder'
  import Incidencia from '@/apps/formalizacion/Componentes/ListaIncidencia'
  import Observacion from '@/apps/formalizacion/Componentes/Observacion'
  import Documento from '@/apps/formalizacion/Componentes/Documento'
  import NoViable from '@/apps/formalizacion/Componentes/NoViable'
  import Calendario from '@/apps/formalizacion/Componentes/Calendario'

  import apiUsuarios from '@/apps/formalizacion/api/v2/usuarios'
  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Step, Retroceder, Observacion, Documento, Calendar, Subasignacion, DocumentacionBasica, DictamenCliente, DictamenInmueble, DocumentacionCompleta,
      SolicitudCLG, CargaCLG, Incidencia, Cita, Firma, Finalizacion, NoViable, Calendario, InformacionAdicionalSolicitud
    }
    ,data() {
      return {
        pasos: [
          {
            texto: "Sub asignación"
            ,valor: "inicio"
          }
          ,{
            texto: "Documentación básica"
            ,valor: "subasignacion"
          }
          ,{
            texto: "Dictamen cliente"
            ,valor: "documentacion_basica"
          }
          ,{
            texto: "Dictamen inmueble"
            ,valor: "dictamen_cliente"
          }
          ,{
            texto: "Solicitud de CLG"
            ,valor: "dictamen_inmueble"
          }
          ,{
            texto: "Carga de CLG"
            ,valor: "solicitud_clg"
          }
          ,{
            texto: "Documentación completa"
            ,valor: "cargar_clg"
          }
          ,{
            texto: "Cita para Firma"
            ,valor: "documentacion_completa"
          }
          ,{
            texto: "Contrato firmado"
            ,valor: "firma_cita"
          }
          ,{
            texto: "Finalización"
            ,valor: "contrato_firmado"
          }
        ]
        ,solicitud: {
          solicitud: null
          ,sla: []
          ,incidencias: []
        }
        ,etapa: 'inicio'
        ,solicitud_cargada: false
        ,retroceder_etapa: false
        ,modal_incidencias: false
        ,modal_observaciones: false
        ,modal_documentos: false
        ,modal_no_viable: false
        ,modal_calendario: false
        ,modal_informacion_adicional:false
      }
    }
    ,mounted() {
      this.obtener_solicitud();
    }
    ,methods: {
      async obtener_solicitud() {
        try {
          this.solicitud = (await apiUsuarios.obtener_solicitud(this.$route.params.solicitud)).data;

          if (this.solicitud.sla.length > 0) {
            let ult_sla = this.obtener_ultima_etapa_activa(this.solicitud.sla);
            this.$log.info('ult_sla: ', ult_sla);

            if (ult_sla.length > 0)
              this.etapa = ult_sla[ult_sla.length-1].tipo;
          }

          this.solicitud_cargada = true;
        }catch(e) {
          this.$log.info('error',e)
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async avanzar_etapa() {
        try {
          let res = (await apiSolicitudes.avanzar_etapa(this.solicitud.id)).data;
          this.$log.info('res',res);

          this.obtener_solicitud();
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,async cerrar_retroceder_actualizar() {
        this.obtener_solicitud();
        this.retroceder_etapa = false;
      }
      ,obtener_ultima_etapa_activa(etapas) {
        let etapas_activas = []

        etapas.forEach(etapa => {
          if (etapa.tipo_usuario == 2 && etapa.estatus == 1)
            etapas_activas.push(etapa);
        })

        return etapas_activas;
      }
    }
    ,computed: {
      incidencias_validas() {
        let incidencias = [];

        this.solicitud.incidencias.forEach(incidencia => {
          if (incidencia.tipo != 'avaluo')
            incidencias.push(incidencia);
        })

        return incidencias;
      }
      ,incidencias_abiertas() {
        let incidencias = 0;

        this.incidencias_validas.forEach(incidencia => {
          if (incidencia.estatus == 1)
            incidencias++;
        })

        return incidencias;
      }
    }
  }
</script>

<style lang="scss">
  .seleccionar_archivo {
    display: none;
  }
</style>