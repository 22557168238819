<template>
  <div>
    <Modal :options="{close:true}" @close="$emit('close')">
      <div class="title">Documentos</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-secondary mr-2" @click="obtener_documentos(true)">Recargar documentos</button>
            <button class="btn btn-info" @click="modal_subir=true">Cargar documento</button>
          </div>
        </div>

        <div class="row">
          <div  v-for="documento in valija.documentos" class="col-sm-4">
            <div class="card mt-3">
              <div class="card-body">
                <h5 class="card-title text-truncate" :title="buscar_nombre(documento.tipo_documento)">{{ buscar_nombre(documento.tipo_documento) }}</h5>
                <h6 class="card-subtitle mb-2 text-muted text-truncate" :title="documento.nombre_original">{{ documento.nombre_original }}</h6>
                <div class="text-right">
                  <a href="#" class="card-link" @click.prevent="descargar_documento(documento)">Descargar archivo</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>

    <Subir v-if="modal_subir" :solicitud="solicitud" @update="cargar_documento_finalizar" @close="modal_subir=false" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import Subir from './CargarDocumento'

  import apiValija from '@/apps/formalizacion/api/v2/valija'
  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  export default {
    components: {
      Modal, Subir
    }
    ,props: {
      solicitud: {
        type:Object
        ,default() {
          return {
            solicitud: null
          }
        }
      }
    }
    ,data() {
      return {
        valija: {
          documentos: []
        }
        ,catalogo: {
          opciones: []
        }
        ,modal_subir: false
      }
    }
    ,mounted() {
      this.obtener_documentos();
      this.obtener_catalogos();
    }
    ,methods: {
      async obtener_documentos(clear) {
        if (typeof clear != 'undefined')
          this.$helper.cache_clear('valija.documentos');

        this.valija = await this.$helper.cache_store('valija.documentos', async () => {
          return (await apiValija.listar_documentos(this.solicitud.solicitud)).data
        })
      }
      ,async obtener_catalogos() {
        try {
          this.catalogo = await this.$helper.cache_store('valija.tipos_archivos', async () => {
            return (await apiValija.obtener_catalogo('tipos_archivos')).data
          })
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,buscar_nombre(tipo) {
        let tipo_archivo = null;

        for(let i=0; i<this.catalogo.opciones.length; i++) {
          if (tipo == this.catalogo.opciones[i].valor)
            tipo_archivo = this.catalogo.opciones[i];
        }

        return tipo_archivo ? tipo_archivo.nombre : 'N/A';
      }
      ,async descargar_documento(documento) {
        try {
          let res = (await apiValija.descargar_documento(this.solicitud.solicitud, documento.id)).data;

          let url = window.URL.createObjectURL(new Blob([res]));
          let link = document.createElement('a');
          link.href = url;
          link.setAttribute('download',documento.nombre_original);
          document.body.appendChild(link);
          link.click();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cargar_documento_finalizar() {
        this.modal_subir=false;
        this.$emit('update');
      }
    }
  }
</script>