<template>
  <div>
    <Modal :options="{close: true}" @close="$emit('close')">
      <div class="title">Observaciones</div>
      <div class="body">
        <div class="observaciones mb-3" id="observaciones">
          <div v-for="observacion in resultados_paginados" class="observacion">
            <div class="descripcion" v-html="observacion.observacion"></div>
            <div class="usuario">{{ observacion.index+1 }} - {{ observacion.nombre }} - {{ $moment(observacion.created_at).format('dddd DD MMMM YYYY HH:mm:ss') }}</div>
            <div class="creacion"></div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12">
            <div class="input-group mb-3">
              <input v-model="observacion" type="text" class="form-control" placeholder="Observación..." aria-label="observacion" aria-describedby="button-addon2">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="enviar_observacion">Agregar</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="total_paginas > 1" class="footer">
        <div class="row">
          <div class="col-sm-12">
            <nav aria-label="Page navigation example">
              <ul class="pagination justify-content-center">
                <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_anterior">Anterior</a></li>
                <template v-for="num_pagina in paginas_mostrar">
                <li :class="'page-item'+(num_pagina == pagina ? ' pagina_actual' : '')"><a class="page-link" href="#" @click.prevent="pagina = num_pagina">{{ num_pagina }}</a></li>
                </template>
                <li class="page-item"><a class="page-link" href="#" @click.prevent="pagina_siguiente">Siguiente</a></li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

  const OBSERVACION_TIPO_SISTEMA = 0;
  const OBSERVACION_TIPO_USUARIO = 1;

  export default {
    components: {
      Modal
    }
    ,props: {
      observacion_tipo_usuario:false, // define si el tipo de observacion que se usara al agregar una nueva cuando sera como usuario si esta en false en el back se guardara como observacion de sistema
      solicitud: {
        type: Object
        ,default() {
          return {
            observaciones: []
          }
        }
      }
    }
    ,data() {
      return {
        pagina: 1
        ,resultados_por_pagina: 10
        ,mostrar_paginas: 3
        ,observacion: null
      }
    }
    ,methods: {
      pagina_anterior() {
        if (this.pagina > 1)
          this.pagina -= 1;
      }
      ,pagina_siguiente() {
        if (this.pagina < this.total_paginas)
          this.pagina += 1;
      }
      ,async enviar_observacion() {
        try {
          let res = (await apiSolicitudes.agregar_observacion(this.solicitud.id,
              {
                tipo: this.observacion_tipo_usuario ? OBSERVACION_TIPO_USUARIO : OBSERVACION_TIPO_SISTEMA,
                observacion: this.observacion
              })).data;

          this.observacion = null;

          this.$log.info('res', res)
          this.$emit('update');
        }catch(e) {
          this.$log.info('Error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
    ,computed: {
      resultados_paginados() {
        let inicio = (this.pagina == 1 ? 0 : ((this.pagina-1) * this.resultados_por_pagina));
        let final = (this.pagina < this.total_paginas ? inicio+this.resultados_por_pagina : this.solicitud.observaciones.length)-1;
        let observaciones = [];

        if (this.solicitud.observaciones && this.solicitud.observaciones.length > this.resultados_por_pagina)
          for(let i=inicio; i<=final; i++) {
            if (this.solicitud.observaciones[i]) {
              this.solicitud.observaciones[i].index = i
              observaciones.push(this.solicitud.observaciones[i]);
            }
          }

        return observaciones;
      }
      ,total_paginas() {
        return this.solicitud.observaciones ? Math.floor(this.solicitud.observaciones.length / this.resultados_por_pagina) : 0;
      }
      ,paginas_mostrar() {
        let paginas = [];

        let inicio = this.pagina  == 1 ? 1 : (this.pagina > this.mostrar_paginas ? (this.pagina-this.mostrar_paginas) : 1);
        let final = this.pagina == this.total_paginas ? this.total_paginas : (this.pagina < (this.total_paginas - this.mostrar_paginas) ? (this.pagina + this.mostrar_paginas) : this.total_paginas);

        for(let i=inicio; i<=final; i++) {
          paginas.push(i);
        } 

        return paginas;
      }
    }
    ,watch: {
      pagina() {
        $('#observaciones').animate({scrollTop: 0}, 'slow');
      }
    }
  }
</script>

<style lang="scss" scoped>
  .body {
    .observaciones {
      max-height: 50vh !important;
      overflow: hidden !important;
      overflow-y: auto !important;
    }
  }

  .observaciones {
    .observacion {
      border:solid 1px #CCCCCC;
      border-radius: 3px;
      margin-bottom: 20px;

      .descripcion {
        padding: 5px 10px;
      }

      .usuario {
        background-color: #E6E6E6;
        padding: 5px 10px;
        font-size: 0.8em;
        border-top: solid 1px #BFBFBF;
      }
    }
  }

  .pagina_actual {
    a {
      background-color: #C4E5C5;
    }
  }
</style>