<template>
  <div>
    <div class="row">
      <div class="col-sm-10 offset-sm-1">
        <div class="row">
          <h1 class="col-sm-12">Solicitud CLG</h1>
        </div>
        <div class="row mb-4">
          <div class="col-sm-12">Indica si ya se a solicitado el CLG</div>
        </div>
        <div class="row form-group mb-4">
          <label for="fecha_solicitud_clg" class="col-form-label col-sm-2">Fecha de solicitud del CLG</label>
          <div class="col-sm-4"><input type="date" v-model="fecha_solicitud_clg" name="fecha_solicitud_clg" id="fecha_solicitud_clg" class="form-control" :class="(!fecha_solicitud_clg) ? 'is-invalid' : 'is-valid'" required></div>
        </div>
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
            <button class="btn btn-success" @click="avanzar()">CLG solicitado</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import apiSolicitudes from '@/apps/formalizacion/api/v2/solicitudes'

export default {
  props: {
    solicitud: {
      type: Object
    }
  }
  , data() {
    return{
      fecha_solicitud_clg: null
    }
  },
  mounted() {
    if (this.solicitud.fecha_solicitud_clg) {
      this.fecha_solicitud_clg = this.$moment(this.solicitud.fecha_solicitud_clg).format('YYYY-MM-DD')
    }
  }
  ,methods: {
   async avanzar(){
     try {

       if(!this.fecha_solicitud_clg){
         this.$helper.showMessage('Error','Debe agregar la fecha de la solicitud para poder avanzar.','error','alert');
         return
       }

       let payload = {
         fecha_solicitud_clg: this.fecha_solicitud_clg
       }
       let res = (await apiSolicitudes.editar_solicitud(this.solicitud.id, payload));
       this.$log.info('res', res);
       this.$emit('avanzar')
     }catch(e) {
       this.$log.info('error',e);
       this.$helper.showAxiosError(e,'Error');
     }
    }
  }
}
</script>